define("discourse/plugins/discourse-post-badges-plugin/discourse/connectors/user-preferences-profile/user-featured-badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      if (attrs.model.custom_fields.featured_badges) {
        component.set('featuredBadges', attrs.model.custom_fields.featured_badges.split(',').map(b => Number(b)));
      }
      component.addObserver('featuredBadges', function () {
        if (this._state === 'destroying') return;
        let badges = component.get('featuredBadges');
        if (badges) {
          attrs.model.custom_fields.featured_badges = badges.join(',');
        }
      });
    }
  };
});